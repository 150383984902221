import React, { useEffect, useState } from "react";
import { teamList } from "../../Base/moduls";

export default function Team({ width }) {
  const [anime, setAnime] = useState(false);

  useEffect(() => {
    if (width <= 1024) {
      setAnime(true);
    } else setAnime(false);
  }, [width]);
  return (
    <div className="team">
      <div className="team__cookie swim">
        <img
          src={process.env.PUBLIC_URL + "./images/cookies/team.png"}
          alt="team"
        />
      </div>
      <div className="auto__container">
        <div className="team__inner">
          <h3
            className=" wow animate__fadeInUp"
            data-wow-duration={anime ? ".5s" : ".8s"}
            data-wow-delay=".2s"
          >
            The <span className="orange">Chef's</span> Crew
          </h3>
          <p className="" style={{ textAlign: "center" }}>
            <a
              href="https://www.linkedin.com/company/chainlabs-dlt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chainlabs
            </a>{" "}
            is the R&D team behind CryptoCookies. <br />
            Nostalgic of the CookieClickers era where you could rise to the top
            by the most basic action on a computer, we wanted to create a
            universe in which anyone could climb the DeFi ladder and earn yield
            in the simplest ways possible. CryptoCookies is free and is made to
            be profitable to anyone in DeFi, from whales to new comers, only the
            quality of your strategy will determine how much yield you get.
          </p>
          <h3></h3>
{/*           <div className="team__inner-row">
            {teamList.map((item, index) => {
              return (
                <TeamItem {...item} key={index} index={index} anime={anime} />
              );
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
}
const TeamItem = (props) => {
  return (
    <div
      className="teamItem wow animate__fadeIn"
      data-wow-duration={props.anime ? ".5s" : ".8s"}
      data-wow-delay={props.anime ? `.2s` : `${0.2 * props.index}s`}
    >
      <div className="teamItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="team" />
      </div>
      <h6>{props.name}</h6>
      <h6 className="uniq">{props.prof}</h6>
      <p className="sm">{props.about}</p>
    </div>
  );
};
